import React from "react";
import { Input , Button } from 'antd';
import { RegEmail , RegName , RegPassword , RegPhone } from "../../config/common/reg";
import { Cookie , getBrowserVersion , _handleTheMainSiteUrlOfBackendResponse} from "../../actions/util";
import { fetchRequest, getPlatform} from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";

import Toast from '@/Toast';
let oldValues = {}; //用於判斷是否提交piwik

class Register extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            EMAIL : '',
            PWD :'',
            USERNAME : '',
            PHONE :'',


            error_phone: false,
			error_name: false,
			error_pwd: false,
        }
		this.Register = this.Register.bind(this);
    }


    /**
	 * 注册
	 */

	isNull(str){
 
		if ( str == "" ) return true;
		
		var regu = "^[ ]+$";
		
		var re = new RegExp(regu);
		
		return re.test(str);
	}

	Register(){
		let that = this;
		let { PHONE , PWD ,USERNAME } = this.state;
		const {addAffiliateParamToUrl} = this.props;
		if ( USERNAME == "" ) {
			document.getElementsByClassName('name-hit-error')[0].innerHTML = '登入昵称不可为空。';
		}
		if(PWD == ""){
			document.getElementsByClassName('pass-hit-error')[0].innerHTML = '密码不可为空。';
		}
		if(PHONE == "") {
			document.getElementsByClassName('phone-hit-error')[0].innerHTML = '联系电话不可为空。';
		}
		if(RegName.test(USERNAME) && RegPhone.test(PHONE) && RegPassword.test(PWD)){

			let params = {
				BlackBoxValue : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
				E2BlackBoxValue : (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '' ,
				affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '' ,
				hidBlackbox : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
				hostName : document.domain,
				mediaCode : Cookie.GetCookieKeyValue('Media') || '' ,
				mobile : '86-' + PHONE,
				password : PWD,
				referer : Cookie.GetCookieKeyValue('Referer') || document.domain,
				username: USERNAME,
			}

			const platform = getPlatform();
			const Apiversion = `?api-version=1.0&brand=JBO&Platform=${platform}`;
			// piwik 追踪
			global.globalGtag && global.globalGtag('jbo_QRP','Registration form' , 'Submit_registration' ,'event');
			Toast.loading();
			fetchRequest(ApiPort.Register,'POST' , params , Apiversion).then(res =>{
				if(res){
					Toast.hide();
					if(res.isSuccess){
						let updatedUrl = addAffiliateParamToUrl(res.url)
						_handleTheMainSiteUrlOfBackendResponse(`${updatedUrl}`)
					}else{
						this.props.openModal(res.message);
					}
				}
			})

		}


	}

	/**
	 * 改变参数值
	 * @param { String } k 变量
	 * @param { String | Number | any } v 参数
	 */
	 setStates(k, v) {
		this.setState({
		  [k]: v
		});
	};

	/**
	 * onChange input
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	 UserInput(e , type){
		let val = e.target.value || '';
		this.setStates(type,val);
		this.regInput(e , type);
		return ;
		// globalGtag(`Search_withdrawalrecord_Carddetail`);
	}

    /**
	 * input Blur
	 * @param {*} e
	 * @param {*} type
	 */
	 inputOnBlur(e,type){
		this.regInput(e , type);
	}


	handleFocus(val , type){
		oldValues[type] = val;
		console.log('oldValue',oldValues);
	}

    /**
	 * 验证Input 方法
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	regInput(e ,type){
		let val = e.target.value || '';
		let that = this;
		this.handleFocus(val , type);
		let inputMap = {
			'USERNAME' : (val)=>{
				if(!RegName.test(val)){
					that.setState({
						error_name : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('name-hit-error')[0].innerHTML = '登入昵称不可为空。';

						}else{
							document.getElementsByClassName('name-hit-error')[0].innerHTML = '登入昵称必须由 6 － 14 位长度的字母或数字组成，中间不得有读音符号、空格或下划线。';
						}

					});
				}else{
					that.setState({
						error_name :  false
					},()=>{
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Username_input' , 'input');
						document.getElementsByClassName('name-hit-error')[0].innerHTML = ''
					});
				}
				return ;
			},
			'PWD' : (val) =>{
				if(!RegPassword.test(val)){
					that.setState({
						error_pwd : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = '密码不可为空。';
						}else{
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = '密码必须包含6-20个字符，字符只限于使用字母和数字';
						}
					})

				}else{
					that.setState({
						error_pwd :  false
					},()=>{
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Password_input' , 'input');
						document.getElementsByClassName('pass-hit-error')[0].innerHTML = '';
					})
				}
				return ;
			},
			'EMAIL' : (val) =>{
				if(!RegEmail.test(val)){
					that.setState({
						error_email : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱不可为空。';
						}else{
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱格式无效';
						}
					})
				}else{
					that.setState({
						error_email :  false
					},()=>{
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Email_input' , 'input');
						document.getElementsByClassName('email-hit-error')[0].innerHTML = '';
					});
				}
				return;

			},
			'PHONE' : (val) =>{
				if(!RegPhone.test(val)){
					that.setState({
						error_phone : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = '联系电话不可为空。';
						}else{
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = '联系电话格式无效，请您核查。';
						}
					})
				}else{
					that.setState({
						error_phone :  false
					},()=>{
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'phone_input' , 'input');
						document.getElementsByClassName('phone-hit-error')[0].innerHTML = '';
					})
				}
				return
			}
		}
		inputMap[type](val);
		return ;
	}


    render(){
        let { USERNAME , PWD  , PHONE } = this.state;
		let { pathname } = this.props;
        return (
            <>
                <div className="register-box web-register" id="mobile-heading">
                    <div className="box-head">
                        <p>快速注册</p>
                    </div>
                    <div className="registration-content">
						<p className="tips">您只需填写以下信息既可开始游戏并获得新会员独家优惠</p>
                        {/* 用户名 */}
						<div className="flexInput">
							<label>用户名：</label>
							<Input value={USERNAME} onFocus={()=>{}} onBlur={(e)=>{this.inputOnBlur(e ,'USERNAME')}} type={'name'} onChange={(e)=>{this.UserInput(e ,'USERNAME')}} maxLength={14} placeholder="登录昵称"></Input>
						</div>
						{/* 用户名错误显示 */}
						{<p style={{marginBottom: this.state.error_name ? '0.3rem' : ''}} className="name-hit-error"></p>}
                        {/* 密码 */}
						<div className="flexInput">
							<label>密码：</label>
							<Input  type={'password'} onBlur={(e)=>{this.inputOnBlur(e ,'PWD')}} onChange={(e)=>{this.UserInput(e ,'PWD')}} maxLength={20} name="password" placeholder="输入密码" value={PWD}></Input>
						</div>
						{ /* password error */ }
						{<p style={{marginBottom: this.state.error_pwd ? '0.3rem' : ''}} className="pass-hit-error"></p>}
                        {/* 邮箱 */}
                        {/*<Input name="email" onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}} maxLength={100} placeholder="邮箱" onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL}></Input>*/}
						{ /* email error */ }
						{/*<p className="email-hit-error"></p>*/}
                         {/* 电话号码 */}
                        <div className="phone-number flexInput">
							<label>手机号码：</label>
                            <Input className="phoneNumber" onBlur={(e)=>{this.inputOnBlur(e ,'PHONE')}} maxLength={11} name="phoneNumber" id="inputPhone" placeholder="电话号码" onChange={(e)=>{this.UserInput(e ,'PHONE')}} value={PHONE}></Input>
                        </div>
						{ /* email iphone Number */ }
						{<p style={{marginBottom: this.state.error_phone ? '0.3rem' : ''}} className="phone-hit-error"></p>}

						{pathname === 'm1-005' || pathname === 'm1-004' ? <p className="tips">点击【注册】表示您已满18周岁,并已阅读且同意规则与条件声明的所有政策。</p> : <p className="tips">点击【注册】按钮表示您已满18周岁,并已阅读且同意规则与条件声明的所有政策。</p>}
						<Button className="register-submit btn-download-pc"  onClick={()=>{this.Register()}}>注册</Button>
						{pathname === 'm1-005' || pathname === 'm1-004' ? <p className="tips">jbo-theme对您提供的信息严格保密，您提供的所有信息都能得到机密保障。</p> : <p className="tips">JBO对您提供的信息严格保密，您提供的所有信息都能得到机密保障。</p>}
                    </div>
                </div>
            </>

        )
    }
}


export default Register;
